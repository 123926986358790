.fa-whatsapp {
  font-size: 50px;
  color: green;
}

.wsp-logo {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px;
  margin: 10px;
  color: rgb(255, 255, 255);
  background-color: rgba(54, 208, 82, 0.73);
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

.wsp-logo:hover {
  background-color: rgb(54, 208, 82);
}

.logo {
  font-weight: bold;
}

.header-container {
  padding: 15px;
  background-color: #4c4cdb;
}

.footer-container{
  min-height: 200px;
  background-color: #4c4cdb;
  color: #ffffff;
}

.footer-logo {
  font-weight: bold;
}

.carousel-caption {
  background-color: rgba(128, 128, 128, 0.122);
  border-radius: 20px;
}

.main-container {
  background-color: white;
}

.products-container{
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  text-align: center;
}

.product-card {
  background-color: white;
  border-radius: 15px;
  color: black;
  padding-bottom: 5px;
  width: 250px;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.product-img {
  border-radius: 15px 15px 0px 0px;
  width: 100%;
}

.product-description {
  margin: 10px;
  font-weight: 500;
  color: #2323cb;
}

.services {
  transition: opacity 0.5s ease-in-out;
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  display: table;
  background-color: #F9F9F9;
  padding: 24px;
    text-align: center;
    color: #2323cb;
}

.products {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  
  justify-content: center;
  text-align: center;
  display: flex;
}

@media (max-width: 768px) {
  .carousel-item img {
    height: 300px;
    width: 350px;
    object-fit: contain;
  }
}

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Asegura que el modal esté en la capa superior */
}

.modal-content{
  overflow-y: auto;
	max-height: calc(100vh - 100px);
}


.custom-modal.is-open {
  display: flex;
}

.custom-modal > div {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  z-index: 1; /* Asegura que el contenido del modal esté encima del fondo oscuro */
}

.close-button {
  display: flex;
  justify-content: flex-end;
}

.close {
  background: none;
  border: none;
  cursor: pointer;
}

.modal-title {

}

.modal-list {
  text-align: left;
}

.close {
  background: none;
  border-radius: 0;
  border: 0;
}

  .carousel-item img {
    height: 300px;
    width: auto;
    object-fit: contain;
  }